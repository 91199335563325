

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store, { persistor } from './Redux/Store';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import AgoraRTC, { AgoraRTCProvider } from "agora-rtc-react";



const config = { 
  mode: "rtc", codec: "vp8",
  // Enable echo cancellation
  audioProcessing: {
    AEC: true, // Echo cancellation
    AGC: true, // Automatic gain control
    ANS: true, // Automatic noise suppression
    bypassWebAudio:true
  }
};

export const client = AgoraRTC.createClient(config);

function Main() {


  return (
    <Router>
<GoogleOAuthProvider clientId="547613690858-hfrota7qng1iokifb9ce93mp1sdoqbuq.apps.googleusercontent.com">
    <Provider store={store}>
    <AgoraRTCProvider client={client}>

    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
    </AgoraRTCProvider>

  </Provider> 
  </GoogleOAuthProvider>
  </Router>
  );
}

ReactDOM.render(<Main />, document.getElementById('root'));
